// QuizDashboard.js
import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './QuizDashboard.css';  // Importar o arquivo CSS separado para o estilo
import config from '../../config.js';
import { useNavigate } from "react-router-dom";

const apiUrl = config.apiUrl; 

ChartJS.register(ArcElement, Tooltip, Legend);

const QuizDashboard = ({ quizName, passed, correctAnswers, wrongAnswers, skippedQuestions, totalQuestions, passingPercentage, attemptNumber, timeTaken, completionDate }) => {
  
  // Cálculo
  const correctPercentage = ((correctAnswers / totalQuestions) * 100).toFixed(0);
  const navigate = useNavigate();

  // Dados do Gráfico
  const data = {
    labels: ['Corretas', 'Erradas', 'Puladas/Não respondidas'],
    datasets: [
      {
        label: '# de Respostas',
        data: [correctAnswers, wrongAnswers, skippedQuestions],
        backgroundColor: [
          'rgba(75, 192, 192, 0.6)',  // Corretas - Verde
          'rgba(255, 99, 132, 0.6)',   // Erradas - Vermelho
          'rgba(201, 203, 207, 0.6)',  // Puladas/Não respondidas - Cinza
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(201, 203, 207, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const reviewQuestions = () => {
    navigate("/quiz-result/0?direction=next");
  };

  return (
    <div className="quiz-dashboard-container container mt-3">
      <div className="shadow quiz-card">
        <div className="card-body">
          <div className="text-center mb-4">
            <h2 className="quiz-title text-primary">Resumo do Quiz - {quizName}</h2>
            <p className="quiz-details mt-2">{totalQuestions} perguntas | {timeTaken} | {passingPercentage}% de acertos necessários para passar</p>
          </div>
          
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-6 mb-4">
              <div className="doughnut-container">
                <Doughnut data={data} options={{ responsive: true, maintainAspectRatio: false }} />
              </div>
            </div>
            <div className="col-12 col-md-6 text-center">
              <h4 className="quiz-attempt">
                Tentativa {attemptNumber}: 
                <span className={`status-text ${passed ? 'passed' : 'failed'}`}>
                  {passed ? ' Aprovado!' : ' Reprovado!'}
                </span> 
                &nbsp;({passingPercentage} % necessários para passar)
              </h4>
              <h2 className="quiz-percentage">
                {correctPercentage}% corretas ({correctAnswers}/{totalQuestions})
              </h2>
              <p className="time-taken">{timeTaken}</p>
              <p className="completion-date">{completionDate}</p>
              <button onClick={() => reviewQuestions()} className="btn btn-primary mt-3 review-btn">Revisar perguntas</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Main Component
const App = () => {
  const [quizData, setQuizData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [authToken, setAuthToken] = useState(() => {
    const saved = localStorage.getItem("token");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  useEffect(() => {
    // Fetch data from the provided API
    const fetchQuizData = async () => {
      try {
        const url = `${apiUrl}/user-quiz/board-result?token=${authToken}`;
        const response = await fetch(url, {
          method: 'GET',
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        // Assuming the API returns an object with necessary properties directly
        setQuizData({
          quizName: data.quizName,
          passed: data.passed,
          correctAnswers: data.correctAnswers,
          wrongAnswers: data.wrongAnswers,
          skippedQuestions: data.skippedQuestions,
          totalQuestions: data.totalQuestions,
          passingPercentage: data.passingPercentage,
          attemptNumber: data.attemptNumber,
          timeTaken: data.timeTaken,
          completionDate: data.completionDate,
        });
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchQuizData();
  }, []);

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>Erro: {error}</div>;
  }

  return (
    quizData && <QuizDashboard {...quizData} />
  );
};

export default App;
