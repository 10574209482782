import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import config from '../../config.js';
import { useLoading } from "../../LoadingContext";
import ReCAPTCHA from 'react-google-recaptcha';

const apiUrl = config.apiUrl;

const ContactPage = () => {
    // State to handle form data
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        type: '',
        message: ''
    });

    // State to handle success and error alerts
    const [successMessage, setSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { loading, setLoading } = useLoading();
    // const [captchaToken, setCaptchaToken] = useState(null);

    // Handle change in input fields
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value
        });
    };

    // const handleCaptchaChange = (token) => {
    //     setCaptchaToken(token); // Save the token received from reCAPTCHA
    // };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission

        // if (!captchaToken) {
        //     setErrorMessage('Por favor, preencha o CAPTCHA.');
        //     return;
        // }
      
        // Prepare the data to send to the API
        const requestData = {
            email: formData.email,
            description: formData.message,
            name: formData.name,
            typeContact: convertTypeToNumber(formData.type)
        };

        try {
            setLoading(true);
            // Clear previous alerts
            setSuccessMessage(false);
            setErrorMessage('');

            const response = await fetch(`${apiUrl}/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            });

            if (response.ok) {
                setSuccessMessage(true); // Show success message
                setFormData({ name: '', email: '', type: '', message: '' }); // Clear form after submission
                // setCaptchaToken(null);
            } else {
                setErrorMessage('Erro ao enviar a mensagem. Por favor, tente novamente.');
            }
        } catch (error) {
            setErrorMessage('Erro ao enviar a mensagem. Por favor, verifique sua conexão e tente novamente.');
        } finally {
            setLoading(false);
        }
    };

    // Helper function to convert type to a number
    const convertTypeToNumber = (type) => {
        switch (type) {
            case 'general':
                return 1;
            case 'technical':
                return 2;
            case 'billing':
                return 3;
            case 'other':
                return 4;
            default:
                return 0;
        }
    };

    return (
        <>
            <div className="contact-container container py-5">
                {/* Header Section */}
                <header className="contact-header text-center mb-4">
                    <h1 class="text-primary">Fale Conosco</h1>
                    <p>Preencha o formulário abaixo e nossa equipe entrará em contato o mais breve possível.</p>
                </header>

                {/* Success Alert */}
                {successMessage && (
                    <div className="alert alert-success text-center align-items-center text-center" role="alert">
                        <i className="bi bi-check-circle-fill me-2"></i>
                        Mensagem enviada com sucesso! Nossa equipe entrará em contato em breve.
                    </div>
                )}

                {/* Error Alert */}
                {errorMessage && (
                    <div className="alert alert-danger text-center align-items-center" role="alert">
                        <i className="bi bi-exclamation-triangle-fill me-2"></i>
                        {errorMessage}
                    </div>
                )}

                {/* Contact Form */}
                <form onSubmit={handleSubmit} className="mx-auto" style={{ maxWidth: '600px' }}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Nome</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Digite seu nome"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">E-mail</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Digite seu e-mail"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="type" className="form-label">Tipo de Dúvida</label>
                        <select
                            className="form-select"
                            id="type"
                            value={formData.type}
                            onChange={handleChange}
                            required
                        >
                            <option value="" disabled>Selecione o tipo de dúvida</option>
                            <option value="general">Geral</option>
                            <option value="technical">Técnica</option>
                            <option value="billing">Financeira</option>
                            <option value="other">Outras</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="message" className="form-label">Mensagem</label>
                        <textarea
                            className="form-control"
                            id="message"
                            rows="5"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder="Digite sua mensagem"
                            required
                        ></textarea>
                    </div>
                    {/* <ReCAPTCHA
                        sitekey="6Le7rFIqAAAAADejd0kj8fwFm4xh2ebmnvt1B1mg" // Replace with your actual site key
                        onChange={handleCaptchaChange}
                    /> */}
                    <div className="text-center">
                        <button type="submit" className="btn btn-success w-100 btn-lg" disabled={loading}>
                            {loading ? 'Enviando...' : 'Enviar Mensagem'}
                        </button>
                    </div>
                </form>
            </div>

            {/* Footer */}
            <footer className="footer bg-dark text-white mt-5 py-4">
                <div className="container text-center">
                    <p className="mb-0">&copy; 2024 Mock4Tech. Todos os direitos reservados.</p>
                    <a href="#" className="text-white mx-2">Termos de Uso</a> |
                    <a href="#" className="text-white mx-2">Política de Privacidade</a>
                </div>
            </footer>
        </>
    );
};

export default ContactPage;
