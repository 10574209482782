import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './Quiz.css';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap'; // Import Modal from react-bootstrap
import { useLoading } from "../../LoadingContext";
import config from '../../config.js';
const apiUrl = config.apiUrl;

const Timer = ({ timeLeft, openConfirmationModal }) => {
  const { t } = useTranslation();

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(timeLeft / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const seconds = timeLeft % 60;

  return (
    <div className="time-card mb-4 d-flex flex-column align-items-center">
      <button className="btn btn-timer text-center w-100 mt-2 text-bold">
        <i className="bi bi-clock"></i>
        &nbsp; 
        {`${t('quiz.timeRemaining')}: ${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`}
      </button> 
      
      <button onClick={openConfirmationModal} className="btn btn-danger text-center w-100 text-white text-bold">
        <i className="bi bi-check-circle"></i> {t('quiz.finishQuiz')}
      </button>
    </div>
  );
};


const QuizFooter = ({ token, currentQuestion, selectedOption, firstOne, lastOne, handleNavigation, openConfirmationModal }) => {
  const { t } = useTranslation();

  return (
    <div className="quiz-footer row mt-3">
      {/* First Column for "Previous" button */}
      <div className="col-6">
        {!firstOne && (
          <button
            className="prev-btn btn btn-secondary w-100"
            disabled={!selectedOption}
            onClick={() => handleNavigation(token, currentQuestion.idUserQuizQuestion, 'previous', firstOne, lastOne)}
          >
            {t('quiz.previous')}
          </button>
        )}
      </div>

      {/* Second Column for "Next" or "Finish" button */}
      <div className="col-6">
        {!lastOne && (
          <button
            className="next-btn btn btn-primary w-100"
            disabled={!selectedOption}
            onClick={() => handleNavigation(token, currentQuestion.idUserQuizQuestion, 'next', firstOne, lastOne)}
          >
            {t('quiz.next')}
          </button>
        )}
        {lastOne && (
          <button onClick={openConfirmationModal} className="btn btn-danger w-100 text-white text-bold">
            {t('quiz.finishQuiz')}
          </button>
        )}
      </div>
    </div>
  );
};

const Quiz = () => {
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [selectedOption, setSelectedOption] = useState('');
  const { loading, setLoading } = useLoading();
  const [error, setError] = useState(null);
  const [timeLeft, setTimeLeft] = useState(600); // 10 minutes
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const params = useParams();
  const currentQuestionId = params.questionId;  
  const [searchParams] = useSearchParams();
  const direction = searchParams.get('direction');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState(() => {
    const saved = localStorage.getItem("token");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const fetchQuestion = async (token='', direction = 'next', currentQuestionId=0) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${apiUrl}/user-quiz/question-asked?token=${token}&currentUserQuestionId=${currentQuestionId}&direction=${direction}`,
        {
          method: 'GET',
          headers: {
            'x-token': token
          }
        }
      );
      const data = await response.json();
      setCurrentQuestion(data);
      setTimeLeft(data.remainingTimeSeconds);

      let checkedElementsIds = data.responses.filter((el) => el.checked).map((el) => el.id);
      console.log("Checked elements:" + JSON.stringify(checkedElementsIds));
      if(checkedElementsIds){
        checkedElementsIds = checkedElementsIds[0];
        setSelectedOption(checkedElementsIds);  
      }
      setLoading(false);
    } catch (error) {
      setError('Failed to fetch question');
      // navigate(`/quiz-by-exam`);
      setLoading(false);
    }
  };

  // Countdown Timer Effect
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prevTime => {
          if (prevTime <= 0) {
            clearInterval(timer);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000); // Decrease every 1 second
  
      // Clean up on component unmount
      return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchQuestion(authToken, direction, currentQuestionId);
  
      // // Update the question number and total questions from the API only when currentQuestion is available
      // if (currentQuestion && currentQuestion.questionNumer !== undefined) {
      //   setCurrentQuestionNumber(currentQuestion.questionNumer);
      // }
  
      // if (currentQuestion && currentQuestion.totalQuestion !== undefined) {
      //   setTotalQuestions(currentQuestion.totalQuestion);
      // }
    };
  
    fetchData();
  }, [authToken, currentQuestionId, direction]);
  
  const handleOptionChange = (id) => {
    setSelectedOption(id);
  };

  if (error) {
    return <div>{error}</div>;
  }

  const handleNavigation = async (token, questionId, direction, firstOne, lastOne) => {  
    try {
      setLoading(true);
      const responseCode = await submitResponse(token, questionId);
      if (responseCode === 201) {
        if (direction === 'next') {
          navigate(`/quiz/${parseInt(questionId)}?direction=${direction}`);
        } else if (direction === 'previous') {
          navigate(`/quiz/${parseInt(questionId)}?direction=${direction}`);
        }
      }
      setLoading(false);
    } catch (error) {
      console.error('Failed to submit response:', error);
      setError('Failed to submit response.');
    }
  };

  const Question = ({currentQuestionNumber, totalQuestions,  responses, questionName, selectedOption, handleOptionChange }) => {
    const { t } = useTranslation();
  
    return (
      <div className="question-container">
        <h5 className="text-left text-primary">Questão {currentQuestionNumber} de {totalQuestions}</h5>
        <h4>{questionName}</h4>
        <div className="options-container">
          {responses && responses.length > 0 ? (
            responses.map((option) => (
              <div onClick={() => handleOptionChange(option.id)}  className="option" key={option.id}>
                {/* Use label properly associated with input for better accessibility */}
                <input
                  id={`option-${option.id}`} 
                  type="radio"
                  name="option"
                  value={option.id}
                  checked={selectedOption === option.id}
                  onChange={() => handleOptionChange(option.id)} 
                />
                <label 
                  className="text-left"
                  htmlFor={`option-${option.id}`} // Link the label to the input by id
                  style={{ cursor: 'pointer' }} // Add cursor pointer for better UX
                >
                  {option.name}
                </label>
              </div>
            ))
          ) : (
            <p>{t('quiz.noOptionsAvailable')}</p>
          )}
        </div>
      </div>
    );
  };
  

  const ProgressBar = ({ currentQuestion, totalQuestions }) => {
    const progressPercentage = totalQuestions
      ? ((currentQuestion / totalQuestions) * 100)
      : 0;
  
    return (
      <div className="progress mb-4">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${progressPercentage}%` }}
          aria-valuenow={progressPercentage}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {currentQuestion} / {totalQuestions}
        </div>
      </div>
    );
  };
  
  const submitResponse = async (token, questionId) => {
    const responses = [selectedOption];
    const payload = {
      token: token,
      userQuizQuestionId: questionId, // Current question ID
      responses: responses,
    };
    const response = await fetch(`${apiUrl}/response-user-quiz`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token,
      },
      body: JSON.stringify(payload),
    });
    if (response.status === 201) {
      return response.status;
    }
    else {      
      throw new Error(`UserQuiz API returned status code ${response.status}`);
    }
  }
  
  const updateQuiz = async (token) => {
    // Define the PATCH request URL
    const patchUrl = `${apiUrl}/user-quiz/${token}`;
  
    // Make the PATCH request
    const patchResponse = await fetch(patchUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token,
      },
    });
  
    if (patchResponse.status === 204) {
      // If PATCH is successful, navigate to the quiz dashboard
      navigate("/quiz-dashboard");
    } else {
      throw new Error(`PATCH request failed with status code ${patchResponse.status}`);
    }
  }

  const finishQuiz = async (token, questionId) => {
    try {
      setLoading(true);
      // Submit question answer
      if(selectedOption){
        await submitResponse(token, questionId);
      }
      else{
        console.log("No peding answer, finishing Quiz...");
      }
      
      // Update Quiz status to finished
      await updateQuiz(token);
      setLoading(false);
    } catch (error) {
      console.error('Failed to submit response:', error);
      setError('Failed to submit response.');
    }
  };
  
  const handleConfirmFinishQuiz = () => {
    setShowConfirmationModal(false);
    finishQuiz(authToken, currentQuestion.idUserQuizQuestion);
  };

  return (
    <div className="quiz-container">
      <h2 className='text-center text-bold mb-2 text-primary'>{t('quiz.quizTitle')}</h2>

      {!loading && (
        <Timer 
          timeLeft={timeLeft}
          openConfirmationModal={() => setShowConfirmationModal(true)}
        />
    ) }

      {/* <ProgressBar currentQuestionNumber={currentQuestionNumber} totalQuestions={totalQuestions} /> */}

      {!loading && currentQuestion.responses && (
        <Question
          currentQuestionNumber={currentQuestion.questionNumer} totalQuestions={currentQuestion.totalQuestion}
          responses={currentQuestion.responses}
          questionName={currentQuestion.questionName}
          selectedOption={selectedOption}
          handleOptionChange={handleOptionChange}
        />
      )}

      {!loading && (
        <>
          <QuizFooter
            token={authToken}
            currentQuestion={currentQuestion}
            selectedOption={selectedOption}
            firstOne={currentQuestion.firstOne}
            lastOne={currentQuestion.lastOne}
            handleNavigation={handleNavigation}
            openConfirmationModal={() => setShowConfirmationModal(true)}
          />
        </>
      )}

      {/* Confirmation Modal */}
      <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Finalizar Quiz</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza que deseja finalizar o Quiz atual?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleConfirmFinishQuiz}>
            Finalizar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const App = () => <Quiz />;

export default App;
