// src/i18n/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

// Initialize i18n
i18n
  .use(HttpApi)
  .use(LanguageDetector)  // Automatically detects the user language
  .use(initReactI18next)   // Passes i18n down to react-i18next
  .init({
    supportedLngs: ['pt'], // Supported languages
    fallbackLng: 'pt',                 // Fallback language
    detection: {
      order: ['localStorage', 'cookie', 'navigator'],  // Use localStorage first
      caches: ['localStorage'],                        // Cache the language in localStorage
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',   // Path to translation files
    },
    react: {
      useSuspense: false,   // Disable suspense
    }
});


export default i18n;
