import logo from '../../assets/img/logo.png'; // Ajuste o caminho conforme a estrutura de arquivos
import './AppHeader.css';
import { useTranslation } from 'react-i18next';
import { Navbar, Nav, Container, Dropdown, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation to get current path

const languageOptions = {
  pt: { label: 'Português', flag: 'https://flagcdn.com/w20/br.png', alt: 'pt-BR' },
  en: { label: 'English', flag: 'https://flagcdn.com/w20/us.png', alt: 'en-US' },
  es: { label: 'Español', flag: 'https://flagcdn.com/w20/es.png', alt: 'es-ES' },
};

const AppHeader = () => {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions.en); // Idioma padrão

  // Carregar idioma do localStorage ao montar o componente
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage && languageOptions[savedLanguage]) {
      setSelectedLanguage(languageOptions[savedLanguage]);
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setSelectedLanguage(languageOptions[lang]);
    localStorage.setItem('language', lang); // Salvar o idioma no localStorage
  };

  return (
    <Navbar expand="lg" variant="dark" className='navbar'>
      <Container>
        <Navbar.Brand href="/">
          <img src={logo} alt="Mock4Tech Logo" className="logo" style={{ height: 70 }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto align-items-center">
            <Nav.Link 
              href="/" 
              className={`navElem ${window.location.pathname === '/' ? 'active' : ''}`}
            >
              {t('home')}
            </Nav.Link>

            {/* <Nav.Link href="/blog" className={`navElem ${location.pathname === '/blog' ? 'active' : ''}`}>
              {t('blog')}
            </Nav.Link> */}

            <Nav.Link 
              href="/contact" 
              className={`navElem ${window.location.pathname === '/contact' ? 'active' : ''}`}
            >
              {t('contact')}
            </Nav.Link>

            {/* Dropdown for language selection */}
            {/* <Dropdown align="center">
              <Dropdown.Toggle className='noBgDropdown' id="dropdown-basic">
                <img
                  src={selectedLanguage.flag}
                  alt={selectedLanguage.alt}
                  style={{ marginRight: 8 }}
                />
                {selectedLanguage.label}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => changeLanguage('pt')}>
                  <img
                    src={languageOptions.pt.flag}
                    alt={languageOptions.pt.alt}
                    style={{ marginRight: 8 }}
                  />
                  {languageOptions.pt.label}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeLanguage('en')}>
                  <img
                    src={languageOptions.en.flag}
                    alt={languageOptions.en.alt}
                    style={{ marginRight: 8 }}
                  />
                  {languageOptions.en.label}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeLanguage('es')}>
                  <img
                    src={languageOptions.es.flag}
                    alt={languageOptions.es.alt}
                    style={{ marginRight: 8 }}
                  />
                  {languageOptions.es.label}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}

            {/* Login Button */}
            <Nav.Link href="/login">
              {!["/login", "/quiz-by-exam", "/quiz", "/quiz-result", "/quiz-dashboard"].includes(window.location.pathname) && (
                <Button className='loginButton'>
                  <i className="bi bi-box-arrow-in-right"></i> {t('login')}
                </Button>
              )}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AppHeader;
