import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import './CertificationDetails.css';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Footer from '../../components/Footer/Footer.js';
import config from '../../config.js';
import StartQuizButton from './StartQuizButton';
import { useLoading } from "../../LoadingContext";

const apiUrl = config.apiUrl;

const CertificationDetails = () => {
  const { t } = useTranslation();
  const [exam, setExam] = useState({});
  const [comingSoon, setComingSoon] = useState(false);
  const params = useParams();
  const { i18n } = useTranslation(); // Hook from react-i18next
  const languagesMap = { "en": "en-US", "pt": "pt-BR", "es": "es-ES" };
  const currentLanguage = i18n.language;
  const languageCode = languagesMap[currentLanguage];
  const navigate = useNavigate();
  const {loading, setLoading } = useLoading();

  // Fetch the exam data from the API
  useEffect(() => {
    setLoading(true);
    let url = `${apiUrl}/exams/home/detail/${params.id}?language=${languageCode}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log("EXAM DATA22:" + JSON.stringify(data));
        setExam(data.certificationDetails);
        setComingSoon(data.certificationDetails.comingSoon);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching the exams:', error);
        setLoading(false);
        navigate("/");
      });
  }, [params.id]);

  const handleStartQuiz = () => {
    navigate("/login"); // Navega para a página de login ou início do quiz
  };

  return (
    <>
      {!loading && exam && (
        <div className="container certification-details">
          {/* Botão para iniciar o quiz no topo */}
          {!comingSoon && ( <StartQuizButton onClick={handleStartQuiz} /> )}
          
          <div className="text-center">
            <img src={exam.img} alt={exam.title} className="certification-logo" />
          </div>
          <h2 className="text-primary text-center">{exam.title}</h2>
          <p className="content" dangerouslySetInnerHTML={{ __html: exam.titleDescription }}></p>
          
          <h2 className="text-primary text-center">{exam.whatTitle}</h2>
          <p className="content" dangerouslySetInnerHTML={{ __html: exam.whatDescription }}></p>
          
          <h2 className="text-primary text-center">{exam.benefitsTitle}</h2>
          <p className="content" dangerouslySetInnerHTML={{ __html: exam.benefitsDescription }}></p>
          
          <h2 className="text-primary text-center">{exam.whatCoveredTitle}</h2>
          <p className="content" dangerouslySetInnerHTML={{ __html: exam.whatCoveredDescription }}></p>
          
          <h2 className="text-primary text-center">{exam.formatTitle}</h2>
          <p className="content" dangerouslySetInnerHTML={{ __html: exam.formatDescription }}></p>
          
          <h2 className="text-primary text-center">{exam.howPrepareTitle}</h2>
          <p className="content" dangerouslySetInnerHTML={{ __html: exam.howPrepareDescription }}></p>
          
          {/* Botão para iniciar o quiz no final */}
          {!comingSoon && ( <StartQuizButton onClick={handleStartQuiz} /> )}

          {/* Botão fixo na página (se necessário) */}
          {/* {exam.hasOwnProperty("comingSoon") && (<StickyCta exam={exam} />)} */}
        </div>
      )}
    </>
  );
};

export default CertificationDetails;
